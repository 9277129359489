import {Auth} from 'aws-amplify'
import i18n from 'i18next'

import {navigationInfo} from '../config/urls'

import {type FetchParams, type Middleware, type RequestContext, type ResponseContext} from './runtime'

// eslint-disable-next-line @typescript-eslint/naming-convention
export class ApiMiddleware implements Middleware {
  public async pre(context: RequestContext): Promise<FetchParams> {
    const headers = new Headers(context.init.headers)
    // set the language
    headers.append('ihq-language', i18n.language)

    // set the authentication
    const accessToken = await this.acquireToken()
    if (accessToken !== undefined) {
      headers.append('Authorization', `Bearer ${accessToken}`)
    }
    return {
      url: context.url,
      init: {
        ...context.init,
        headers
      }
    }
  }

  public async post(context: ResponseContext): Promise<Response> {
    const {internalError} = navigationInfo

    if (!context.response.ok) {
      if (context.response.status === 500) {
        window.location.href = internalError.getUrl()
      }

      throw Error(context.response.statusText)
    }

    return await Promise.resolve(context.response)
  }

  private async acquireToken(): Promise<string | undefined> {
    try {
      const session = await Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch (error) {
      // A try-catch is needed because Auth.currentSession() can fail if no user is logged in.
      // No need to trigger any action. If the backend needs the token, it will throw a FORBIDDEN error
    }
  }
}
