import {Amplify} from 'aws-amplify'

import {initialiseMixpanel} from '../utils/mixpanel'

import awsExports from './aws-exports'
import {initI18} from './i18n'

const documentHeight = (): void => {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}

const configureApp = (): void => {
  // INTERNATIONALIZATION
  initI18()

  // AMPLIFY
  Amplify.configure(awsExports)

  // MIXPANEL
  // Get consent status: https://developers.hubspot.com/docs/api/events/cookie-banner
  if (window._hsp?.push !== undefined) {
    window._hsp.push([
      'addPrivacyConsentListener',
      function (consent: any) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        if (consent?.categories?.analytics === true) {
          initialiseMixpanel()
        }
      }
    ])
  }

  // RESIZE EVENT
  window.addEventListener('resize', documentHeight)
  documentHeight()
}

export default configureApp
