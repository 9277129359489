export default {
  signIn: {
    username: {
      label: 'auth:authScreen.emailLabel',
      placeholder: 'auth:authScreen.emailPlaceholder'
    },
    password: {
      placeholder: 'Password'
    }
  },
  signUp: {
    given_name: {
      label: 'auth:authScreen.firstNameLabel',
      placeholder: 'auth:authScreen.firstNamePlaceholder',
      order: 1
    },
    family_name: {
      label: 'auth:authScreen.lastNameLabel',
      placeholder: 'auth:authScreen.lastNamePlaceholder',
      order: 2
    },
    username: {
      label: 'auth:authScreen.emailLabel',
      placeholder: 'auth:authScreen.emailPlaceholder',
      order: 3
    },
    password: {
      placeholder: 'auth:authScreen.enterPassword',
      order: 4
    },
    confirm_password: {
      placeholder: 'auth:authScreen.confirmPassword',
      order: 5
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'Code',
      placeholder: 'auth:authScreen.codePlaceholder'
    },
    password: {
      placeholder: 'Password'
    },
    confirm_password: {
      placeholder: 'Password'
    }
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'auth:authScreen.otpLabel',
      placeholder: 'auth:authScreen.codePlaceholder'
    }
  },
  confirmSignUp: {
    confirmation_code: {
      label: 'auth:authScreen.confirmationCodeLabel',
      placeholder: 'auth:authScreen.confirmationCodePlaceholder'
    }
  },
  forceNewPassword: {
    given_name: {
      label: 'auth:authScreen.firstNameLabel',
      placeholder: 'auth:authScreen.firstNamePlaceholder',
      order: 1
    },
    family_name: {
      label: 'auth:authScreen.lastNameLabel',
      placeholder: 'auth:authScreen.lastNamePlaceholder',
      order: 2
    }
  },
  resetPassword: {
    username: {
      label: 'auth:authScreen.emailLabel',
      placeholder: 'auth:authScreen.emailPlaceholder'
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'Insights Store',
      totpUsername: ''
    },
    confirmation_code: {
      label: 'auth:authScreen.otpLabel',
      placeholder: 'auth:authScreen.codePlaceholder'
    }
  }
}
