/** @jsxImportSource @emotion/react */
import {Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import AuthHeader from '../authHeader/authHeader'

import styles from './styles'

interface ISetupOTPProps {
  closeButton?: JSX.Element
}

const SetupOTP = ({closeButton}: ISetupOTPProps): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()

  return (
    <>
      <AuthHeader closeButton={closeButton} title={t('auth:authScreen.mobileAuthenticator')} />
      <Typography sx={styles.description} variant="body1">
        <i className="_e275__Warning_24px" css={styles.warningIcon} />
        {t('auth:authScreen.setUpOtp')}
      </Typography>
      <Typography sx={styles.text} variant="body1">
        1. {t('auth:authScreen.otpConfigSteps.0')}
      </Typography>
      <div css={styles.linkContainer}>
        <a css={styles.link(theme.palette.primary.main)} href="https://freeotp.github.io">
          FreeOTP
        </a>
        <a
          css={styles.link(theme.palette.primary.main)}
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
          Google Authenticator
        </a>
      </div>
      <Typography sx={styles.text} variant="body1">
        2. {t('auth:authScreen.otpConfigSteps.1')}
      </Typography>
    </>
  )
}

export default SetupOTP
