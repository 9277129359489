const styles = {
  loginContainer: {
    fontSize: '1rem'
  },
  button: {
    fontSize: '1.8rem',
    minWidth: '170px',
    width: 'fit-content',
    display: {xs: 'none', lg: 'block'}
  },
  avatar: {
    height: '36px',
    textTransform: 'uppercase' as const,
    width: '36px',
    fontSize: '1.8rem'
  },
  menu: {
    width: '290px',
    fontSize: '1rem'
  },
  listItemAvatar: {
    minWidth: '42px',
    margin: '10px 0px'
  },
  menuListPrimary: {
    fontSize: '1.4rem',
    marginBottom: '0px',
    width: '195px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whitespace: 'nowrap'
  },
  menuListSecondary: {
    fontSize: '.9rem'
  },
  backdrop: {
    background: 'transparent'
  },
  paper: {
    boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.5)',
    borderRadius: '16px'
  },
  bottomDivider: {
    paddingTop: '15px'
  },
  modalContainer: {
    display: 'flex',
    p: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalBackdrop: {
    backgroundColor: 'rgba(15,7,42,0.9)'
  },
  boxContainer: {
    backgroundColor: 'white',
    borderRadius: '16px',
    textAlign: 'center',
    width: '432px'
  },
  logoutButton: {
    fontSize: '1.8rem',
    height: '50px',
    marginBottom: '24px',
    marginInline: 'auto',
    width: '260px'
  },
  emailHeader: {
    fontFamily: '"BlenderPro_medium"',
    margin: '24px 0'
  }
}

export default styles
