/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECustomReportItemType } from './ECustomReportItemType';
import {
    ECustomReportItemTypeFromJSON,
    ECustomReportItemTypeFromJSONTyped,
    ECustomReportItemTypeToJSON,
} from './ECustomReportItemType';

/**
 * 
 * @export
 * @interface CustomReportCatalogueItemDto
 */
export interface CustomReportCatalogueItemDto {
    /**
     * 
     * @type {string}
     * @memberof CustomReportCatalogueItemDto
     */
    id: string;
    /**
     * 
     * @type {ECustomReportItemType}
     * @memberof CustomReportCatalogueItemDto
     */
    type: ECustomReportItemType;
    /**
     * 
     * @type {string}
     * @memberof CustomReportCatalogueItemDto
     */
    typeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof CustomReportCatalogueItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CustomReportCatalogueItemDto
     */
    description: string;
}

/**
 * Check if a given object implements the CustomReportCatalogueItemDto interface.
 */
export function instanceOfCustomReportCatalogueItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "typeLabel" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CustomReportCatalogueItemDtoFromJSON(json: any): CustomReportCatalogueItemDto {
    return CustomReportCatalogueItemDtoFromJSONTyped(json, false);
}

export function CustomReportCatalogueItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomReportCatalogueItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ECustomReportItemTypeFromJSON(json['type']),
        'typeLabel': json['typeLabel'],
        'title': json['title'],
        'description': json['description'],
    };
}

export function CustomReportCatalogueItemDtoToJSON(value?: CustomReportCatalogueItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ECustomReportItemTypeToJSON(value.type),
        'typeLabel': value.typeLabel,
        'title': value.title,
        'description': value.description,
    };
}

