import {useAuthenticator} from '@aws-amplify/ui-react'
import {IconButton, Typography, Stack, Button, Box} from '@mui/material'
import {t} from 'i18next'
import React from 'react'

import {type IFeatureFlagsContext} from '../../../../contexts/featureFlags'
import AuthHeader from '../../authHeader/authHeader'
import AuthTabsHeader from '../../authTabsHeader/authTabsHeader'
import SetupOTP from '../../setupOTP/setupOTP'

import styles from './styles'

interface ICloseButtonProps {
  onClose?: () => void
}

const closeButton = ({onClose}: ICloseButtonProps): any => (
  <>
    {onClose !== undefined && (
      <Box sx={styles.closeContainer}>
        <IconButton sx={styles.closeButton} onClick={onClose}>
          <i className="_e216_Delete_8px" />
        </IconButton>
      </Box>
    )}
  </>
)

const closeButtonNoPadding = ({onClose}: ICloseButtonProps): any => (
  <>
    {onClose !== undefined && (
      <Box sx={styles.closeContainer}>
        <IconButton sx={styles.closeButtonNoPadding} onClick={onClose}>
          <i className="_e216_Delete_8px" />
        </IconButton>
      </Box>
    )}
  </>
)

const passwordRules = (
  <>
    <Typography sx={styles.subtitle} variant="h2">
      {t('auth:authScreen.passwordContain')}
    </Typography>
    <Box sx={styles.listContainer}>
      <ul style={styles.columnData}>
        <li style={styles.rowData}>{t('auth:authScreen.passwordRules.0')}</li>
        <li style={styles.rowData}>{t('auth:authScreen.passwordRules.1')}</li>
        <li style={styles.rowData}>{t('auth:authScreen.passwordRules.2')}</li>
        <li style={styles.rowData}>{t('auth:authScreen.passwordRules.3')}</li>
        <li style={styles.rowData}>{t('auth:authScreen.passwordRules.4')}</li>
      </ul>
    </Box>
  </>
)

interface IAmplifyComponentsProps {
  features: IFeatureFlagsContext
  isMobile: boolean
  onClose?: () => void
}

export default ({features, isMobile, onClose}: IAmplifyComponentsProps): any => ({
  ConfirmResetPassword: {
    Header() {
      return (
        <AuthHeader
          closeButton={!isMobile ? closeButtonNoPadding({onClose}) : undefined}
          description={t('auth:authScreen.enterCode')}
          passwordRules={passwordRules}
          title={t('auth:authScreen.resetPassword')}
        />
      )
    }
  },
  ConfirmSignIn: {
    Header() {
      return (
        <AuthHeader
          closeButton={!isMobile ? closeButtonNoPadding({onClose}) : undefined}
          title={t('auth:authScreen.confirmSignIn')}
        />
      )
    }
  },
  ConfirmSignUp: {
    Header() {
      const {toSignIn} = useAuthenticator()

      return (
        <>
          <AuthTabsHeader
            closeButton={!isMobile ? closeButton({onClose}) : undefined}
            tab="signUp"
            onChange={toSignIn}
          />
          <Stack alignItems="center">
            <Typography variant="h3">{t('auth:authScreen.confirmSignUp')}</Typography>
            <i className="_e737__Email_32px" css={styles.emailIcon} />
          </Stack>
        </>
      )
    }
  },
  ResetPassword: {
    Header() {
      return (
        <AuthHeader
          closeButton={!isMobile ? closeButtonNoPadding({onClose}) : undefined}
          description={t('auth:authScreen.enterEmail')}
          title={t('auth:authScreen.resetPassword')}
        />
      )
    }
  },
  SetupTOTP: {
    Header() {
      return <SetupOTP closeButton={!isMobile ? closeButton({onClose}) : undefined} />
    }
  },
  SignIn: {
    Header() {
      const {toSignUp} = useAuthenticator()

      return features.isSignUpEnabled ? (
        <AuthTabsHeader closeButton={!isMobile ? closeButton({onClose}) : undefined} tab="signIn" onChange={toSignUp} />
      ) : (
        <AuthHeader
          closeButton={!isMobile ? closeButtonNoPadding({onClose}) : undefined}
          title={t('auth:authScreen.signIn')}
        />
      )
    },
    Footer() {
      const {toResetPassword} = useAuthenticator()

      return (
        <Button sx={styles.linkText} variant="text" onClick={toResetPassword}>
          {t('auth:authScreen.forgottenPassword')}
        </Button>
      )
    }
  },
  SignUp: {
    Header() {
      const {toSignIn} = useAuthenticator()

      return (
        <AuthTabsHeader
          closeButton={!isMobile ? closeButton({onClose}) : undefined}
          passwordRules={passwordRules}
          tab="signUp"
          onChange={toSignIn}
        />
      )
    }
  }
})
