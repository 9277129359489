/** @jsxImportSource @emotion/react */
import {TabContext} from '@mui/lab'
import {Box, Tab, Tabs} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import styles from './styles'

interface IAuthTabsHeaderProps {
  closeButton?: JSX.Element
  onChange: () => void
  passwordRules?: JSX.Element
  tab: string
}

const AuthTabsHeader = ({closeButton, onChange, passwordRules, tab}: IAuthTabsHeaderProps): JSX.Element => {
  const {t} = useTranslation()

  return (
    <>
      {closeButton}
      <TabContext value={tab}>
        <Box>
          <Tabs centered sx={styles.tabs} textColor="inherit" value={tab} variant="fullWidth" onChange={onChange}>
            <Tab label={t('auth:authScreen.signIn')} sx={styles.tabsTitle} value="signIn" />
            <Tab label={t('auth:authScreen.signUp')} sx={styles.tabsTitle} value="signUp" />
          </Tabs>
        </Box>
      </TabContext>
      {passwordRules}
    </>
  )
}

export default AuthTabsHeader
