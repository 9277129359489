import {createSlice, type PayloadAction} from '@reduxjs/toolkit'

import {type TRootState} from '../store'

export interface IAuthState {
  signedIn: boolean
}

const initialState: IAuthState = {
  signedIn: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSignedIn: (state, action: PayloadAction<IAuthState>) => {
      state.signedIn = action.payload?.signedIn
    }
  }
})

export const {setSignedIn} = authSlice.actions

export const getSignedIn = (state: TRootState): boolean => state.auth.signedIn

export default authSlice.reducer
