import {createSlice, type PayloadAction} from '@reduxjs/toolkit'

import {type ECardType} from '../../types'
import {type TRootState} from '../store'

export interface IInsightCatalogueState {
  selectedInsight: string | undefined
  selectedCardType: ECardType | undefined
}

const initialState: IInsightCatalogueState = {
  selectedInsight: undefined,
  selectedCardType: undefined
}

export const insightCatalogueSlice = createSlice({
  name: 'insightCatalogue',
  initialState,
  reducers: {
    selectInsight: (state, action: PayloadAction<IInsightCatalogueState | undefined>) => {
      state.selectedInsight = action.payload?.selectedInsight
      state.selectedCardType = action.payload?.selectedCardType
    }
  }
})

export const {selectInsight} = insightCatalogueSlice.actions

export const selectSelectedInsight = (state: TRootState): string | undefined => state.insightCatalogue.selectedInsight

export const selectSelectedCardType = (state: TRootState): ECardType | undefined =>
  state.insightCatalogue.selectedCardType

export default insightCatalogueSlice.reducer
