/** @jsxImportSource @emotion/react */
import {Typography} from '@mui/material'
import React from 'react'

import styles from './styles'

interface IAuthHeaderProps {
  closeButton?: JSX.Element
  description?: string
  passwordRules?: JSX.Element
  title: string
}

const AuthHeader = ({closeButton, description, passwordRules, title}: IAuthHeaderProps): JSX.Element => (
  <div css={styles.authContainer}>
    {closeButton}
    <Typography sx={styles.title} variant="h2">
      {title}
    </Typography>
    <Typography sx={styles.description} variant="body1">
      {description}
    </Typography>
    {passwordRules}
  </div>
)

export default AuthHeader
