import {env} from '../env'

const awsExports = {
  Auth: {
    userPoolId: env.REACT_APP_USER_POOL_ID,
    region: env.REACT_APP_REGION,
    userPoolWebClientId: env.REACT_APP_CLIENT_ID
  }
}

export default awsExports
