import {LOCAL_STORAGE_COGNITO} from '../config/constVariables'
import {env} from '../env'

export const clearCognitoStorage = (): void => {
  const items = {...localStorage}

  Object.keys(items)
    .filter(
      (item) =>
        env.REACT_APP_CLIENT_ID !== undefined && item.startsWith(`${LOCAL_STORAGE_COGNITO}.${env.REACT_APP_CLIENT_ID}`)
    )
    .forEach((item) => localStorage.removeItem(item))
}
