/** @jsxImportSource @emotion/react */
import {Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import ErrorMessage from '../../../components/errorMessage/errorMessage'
import Main from '../../../layout/main/main'

import {dynamicStyles, styles} from './styles'

const InternalError = (): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()
  const {tryAgain} = dynamicStyles({color: theme.palette.primary.main})

  return (
    <Main showAuth={false} showFooter={false}>
      <div css={styles.errorContainer}>
        <ErrorMessage error={500}>
          <Typography sx={tryAgain} variant="h5">
            {t('shared:errors.tryAgain')}
          </Typography>
        </ErrorMessage>
      </div>
    </Main>
  )
}

export default InternalError
