/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ECustomReportItemType = {
    Brand: 'brand',
    Category: 'category',
    Expenditure: 'expenditure',
    Location: 'location',
    PurchaseBehaviour: 'purchase-behaviour'
} as const;
export type ECustomReportItemType = typeof ECustomReportItemType[keyof typeof ECustomReportItemType];


export function ECustomReportItemTypeFromJSON(json: any): ECustomReportItemType {
    return ECustomReportItemTypeFromJSONTyped(json, false);
}

export function ECustomReportItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECustomReportItemType {
    return json as ECustomReportItemType;
}

export function ECustomReportItemTypeToJSON(value?: ECustomReportItemType | null): any {
    return value as any;
}

