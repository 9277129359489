export default {
  authContainer: {
    paddingTop: '14px'
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  description: {
    textAlign: 'center'
  }
}
