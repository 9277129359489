/** @jsxImportSource @emotion/react */
import {Authenticator} from '@aws-amplify/ui-react'
import {useMediaQuery, useTheme} from '@mui/material'
import {Hub} from 'aws-amplify'
import React, {useCallback, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import '@aws-amplify/ui-react/styles.css'
import './authScreen.css'
import {useAppDispatch} from '../../app/hooks'
import {setSignedIn} from '../../app/slices/authSlice'
import {FeatureFlags, type IFeatureFlagsContext} from '../../contexts/featureFlags'
import {ETrackedEvents} from '../../types'
import {trackEvent} from '../../utils/mixpanel'

import amplifyComponents from './amplify/amplifyComponents/amplifyComponents'
import amplifyFormFields from './amplify/amplifyFormFields'
import amplifyI18n from './amplify/amplifyI18n'
import amplifyServices from './amplify/amplifyServices'

interface IAuthScreenProps {
  logOut?: () => void
  onClose?: () => void
}

const AuthScreen = ({logOut, onClose}: IAuthScreenProps): JSX.Element => {
  const {i18n} = useTranslation()
  const features = useContext<IFeatureFlagsContext>(FeatureFlags)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  amplifyI18n(i18n.language)

  const listener = useCallback(
    (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          dispatch(setSignedIn({signedIn: true}))
          if (onClose !== undefined) onClose()
          trackEvent(ETrackedEvents.UserSignedIn, {Lang: i18n.language})
          break
        case 'signIn_failure':
        case 'tokenRefresh_failure':
        case 'signOut':
          if (logOut !== undefined) logOut()
          break
      }
    },
    [dispatch, logOut, onClose, i18n]
  )

  useEffect(() => {
    const sendAction = Hub.listen('auth', listener)
    return () => {
      sendAction()
    }
  }, [listener])

  return (
    <Authenticator
      components={amplifyComponents({features, isMobile, onClose})}
      formFields={amplifyFormFields}
      hideSignUp={!features.isSignUpEnabled}
      services={amplifyServices}
    />
  )
}

// Without this we were getting a Failed prop type: Invalid prop `children` supplied to `ForwardRef(Modal)` error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AuthScreenWithRef = React.forwardRef((props: IAuthScreenProps, ref) => <AuthScreen onClose={props.onClose} />)

export default AuthScreenWithRef
