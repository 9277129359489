export default {
  bookDemoButton: {
    fontSize: '1.8rem',
    height: '50px',
    marginBottom: '24px',
    marginInline: 'auto',
    width: '260px'
  },
  mobileMenuContainer: {
    marginInline: 'auto',
    paddingInline: '14px',
    maxWidth: '540px'
  },
  mobileButtonsContainer: {
    marginInline: 'auto',
    paddingInline: '14px',
    maxWidth: '260px'
  },
  icon: {
    fontSize: '14px'
  }
}
