/** @jsxImportSource @emotion/react */
import {Box} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../app/hooks'
import {getSignedIn} from '../../../app/slices/authSlice'
import {type InsightCatalogueItemDto} from '../../../backendApi/models'
import {useOpenExplorer} from '../../../utils/useOpenExplorer'
import AuthScreen from '../../authScreen/authScreen'
import {CustomDrawer} from '../../customMUI/customDrawer/customDrawer'
import {ModelTraining} from '../../modelTraining/modelTraining'
import {MarketExplorerExample} from '../../reports/marketExplorerExample/marketExplorerExample'

import {MarketExplorerDescription} from './marketExplorerDescription'
import {ReportDescription} from './reportDescription/reportDescription'
import styles from './styles'

interface IReportDetailsExtendedProps {
  details: InsightCatalogueItemDto
}

export const ReportDetailsInfo = ({details}: IReportDetailsExtendedProps) => {
  const {t} = useTranslation()

  const openExplorer = useOpenExplorer()
  const isSignedIn = useAppSelector(getSignedIn)

  const [openLoginDrawer, setOpenLoginDrawer] = useState<boolean>(false)

  useEffect(() => {
    if (openLoginDrawer && isSignedIn) {
      setOpenLoginDrawer(false)
      if (details.insightMetadata.dashboardUrl) {
        openExplorer(details.insightMetadata.dashboardUrl)
      }
    }
  }, [details.insightMetadata.dashboardUrl, isSignedIn, openExplorer, openLoginDrawer])

  return (
    <Box sx={styles.root}>
      <ReportDescription insight={details.insightMetadata} />
      <Box sx={styles.tableContainer}>
        <MarketExplorerExample
          brands={details.insightMetadata.brands?.map((brand) => brand.label)}
          title={t('insightCatalogue:insightsDetail.tableReportTitle.reports')}
        />
      </Box>
      <MarketExplorerDescription descriptionSections={details.insightMetadata.descriptionSections} />
      {details.insightMetadata.coverage !== undefined &&
        details.insightMetadata.numberOfEnrichments !== undefined &&
        details.insightMetadata.numberOfUniqueCRMRecordsTrained !== undefined && (
          <ModelTraining
            coverage={details.insightMetadata.coverage}
            endDate={details.insightMetadata.dataTimeFrame?.endDate}
            numberOfEnrichments={details.insightMetadata.numberOfEnrichments}
            numberOfUniqueCRMRecordsTrained={details.insightMetadata.numberOfUniqueCRMRecordsTrained}
            startDate={details.insightMetadata.dataTimeFrame?.startDate}
          />
        )}
      <LoginDrawer open={openLoginDrawer} onClose={() => setOpenLoginDrawer(false)} />
    </Box>
  )
}

interface ILoginDrawerProps {
  open: boolean
  onClose: () => void
}

const LoginDrawer = ({open, onClose}: ILoginDrawerProps) => {
  const {t} = useTranslation()

  return (
    <CustomDrawer
      open={open}
      title={t('insightCatalogue:insightsDetail.marketReportSample.loginDrawer.title')}
      onClose={onClose}>
      <>
        <Typography>{t('insightCatalogue:insightsDetail.marketReportSample.loginDrawer.description')}</Typography>
        <div css={styles.loginContainer}>
          <AuthScreen />
        </div>
      </>
    </CustomDrawer>
  )
}
