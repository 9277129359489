import {authErrorMessages} from '@aws-amplify/auth/lib-esm/Errors'
import {translations} from '@aws-amplify/ui-react'
import {I18n} from 'aws-amplify'
import {t} from 'i18next'

import {SUPPORTED_LANGUAGES} from '../../../config/constVariables'

const amplifyI18n = (lng: string): void => {
  I18n.putVocabularies(translations)
  I18n.setLanguage(lng)

  // Here we are storing some Cognito error messages into const variables for them and their translation to be added to the vocabularies
  const cognitoEmptyPasswordError = 'Custom auth lambda trigger is not configured for the user pool.'
  const cognitoInvalidPasswordError =
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$"
  const cognitoInvalidCodeError = 'Invalid code received for user'
  const cognitoInvalidEmailError = 'Username should be an email.'
  const cognitoInvalidVerificationCodeError = 'Invalid verification code provided, please try again.'
  const cognitoCodeMismatchError = 'Code mismatch'
  const cognitoInvalidOTPCodeError =
    "2 validation errors detected: Value '' at 'userCode' failed to satisfy constraint: Member must satisfy regular expression pattern: [0-9]+; Value '' at 'userCode' failed to satisfy constraint: Member must have length greater than or equal to 6"
  const cognitoLimitExceededError = 'Attempt limit exceeded, please try after some time.'
  const cognitoConfirmPasswordRequiredError = 'Unrecognizable lambda output'
  const cognitoPasswordNotLongEnoughError = 'Password did not conform with policy: Password not long enough'
  const cognitoUndefinedFieldsError =
    "CustomMessage failed with error Cannot read properties of undefined (reading 'REQUIRE_ENCRYPT_ALLOW_DECRYPT')."
  const cognitoSpacesInEmailError =
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
  const cognitoSpacesInEmailError2 =
    "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"

  /* This is to add the vocabuary & translation for some AuthErrors and Cognito errors for which there's no translation available in the translations dictionaries. 
    (https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/i18n/translations.ts) */

  const vocabularies = Object.values(SUPPORTED_LANGUAGES).reduce((previousValue, currentValue) => {
    const newVocabularies = {
      ...previousValue,
      [currentValue.isoCode]: {
        // The following are AuthErrors from amplify to which we can directly access to translate them here.
        // (https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/common/AuthErrorStrings.ts)
        [authErrorMessages.emptyPassword.message]: t('auth:authScreen.emptyPassword', {lng: currentValue.isoCode}),
        [authErrorMessages.emptyCode.message]: t('auth:authScreen.emptyConfirmationCode', {lng: currentValue.isoCode}),
        [authErrorMessages.emptyUsername.message]: t('auth:authScreen.emptyEmail', {lng: currentValue.isoCode}),
        // The following are errors coming from Cognito.
        // We cannot access to them directly so we add the messages & translations for the message strings here.
        [cognitoEmptyPasswordError]: t('auth:authScreen.emptyPassword', {
          lng: currentValue.isoCode
        }),
        [cognitoInvalidPasswordError]: t('auth:authScreen.invalidPassword', {
          lng: currentValue.isoCode
        }),
        [cognitoInvalidCodeError]: t('auth:authScreen.invalidCode', {
          lng: currentValue.isoCode
        }),
        [cognitoInvalidEmailError]: t('auth:authScreen.invalidEmail', {
          lng: currentValue.isoCode
        }),
        [cognitoInvalidVerificationCodeError]: t('auth:authScreen.invalidVerificationCode', {
          lng: currentValue.isoCode
        }),
        [cognitoCodeMismatchError]: t('auth:authScreen.codeMismatch', {
          lng: currentValue.isoCode
        }),
        [cognitoInvalidOTPCodeError]: t('auth:authScreen.invalidOTPCode', {
          lng: currentValue.isoCode
        }),
        [cognitoLimitExceededError]: t('auth:authScreen.limitExceeded', {
          lng: currentValue.isoCode
        }),
        [cognitoConfirmPasswordRequiredError]: t('auth:authScreen.confirmPassword', {lng: currentValue.isoCode}),
        [cognitoPasswordNotLongEnoughError]: t('auth:authScreen.passwordTooShort', {lng: currentValue.isoCode}),
        [cognitoUndefinedFieldsError]: t('auth:authScreen.somethingWentWrong', {lng: currentValue.isoCode}),
        [cognitoSpacesInEmailError]: t('auth:authScreen.invalidEmail', {
          lng: currentValue.isoCode
        }),
        [cognitoSpacesInEmailError2]: t('auth:authScreen.invalidEmail', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.codePlaceholder': t('auth:authScreen.codePlaceholder', {lng: currentValue.isoCode}),
        'auth:authScreen.confirmPassword': t('auth:authScreen.confirmPassword', {lng: currentValue.isoCode}),
        'auth:authScreen.confirmationCodeLabel': t('auth:authScreen.confirmationCodeLabel', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.confirmationCodePlaceholder': t('auth:authScreen.confirmationCodePlaceholder', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.emailLabel': t('auth:authScreen.emailLabel', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.emailPlaceholder': t('auth:authScreen.emailPlaceholder', {lng: currentValue.isoCode}),
        'auth:authScreen.enterPassword': t('auth:authScreen.enterPassword', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.firstNameLabel': t('auth:authScreen.firstNameLabel', {lng: currentValue.isoCode}),
        'auth:authScreen.firstNamePlaceholder': t('auth:authScreen.firstNamePlaceholder', {lng: currentValue.isoCode}),
        'auth:authScreen.lastNameLabel': t('auth:authScreen.lastNameLabel', {
          lng: currentValue.isoCode
        }),
        'auth:authScreen.lastNamePlaceholder': t('auth:authScreen.lastNamePlaceholder', {lng: currentValue.isoCode}),
        'auth:authScreen.otpLabel': t('auth:authScreen.otpLabel', {
          lng: currentValue.isoCode
        })
      }
    }

    return newVocabularies
  }, {})

  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#internationalization-i18n
  I18n.putVocabularies(vocabularies)
}

export default amplifyI18n
