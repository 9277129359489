export default {
  subtitle: {
    fontSize: '16px',
    margin: '34px 24px 0'
  },
  linkText: {
    fontFamily: 'BlenderPro_medium',
    fontSize: '1.3rem',
    lineHeight: '1',
    margin: 'auto',
    padding: '0',
    textAlign: 'center',
    width: '100%',
    marginBottom: '36px'
  },
  closeContainer: {
    display: 'flex',
    height: '20px',
    justifyContent: 'flex-end',
    width: '100%'
  },
  closeButton: {
    fontSize: '1.3rem',
    margin: '14px 24px',
    padding: '0'
  },
  closeButtonNoPadding: {
    fontSize: '1.3rem'
  },
  listContainer: {
    margin: '4px 0px 24px'
  },
  columnData: {
    marginBottom: '0px',
    marginTop: '0px'
  },
  rowData: {
    fontFamily: 'noto_sansregular',
    fontSize: '14px'
  },
  emailIcon: {
    alignItems: 'center',
    backgroundColor: '#E2E1E5',
    borderRadius: '30px',
    display: 'flex',
    fontSize: '28px',
    height: '60px',
    justifyContent: 'center',
    width: '60px'
  },
  authContainer: {
    paddingTop: '14px'
  }
}
