import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'

import {SUPPORTED_LANGUAGES} from './constVariables'

const i18nNamespaces = ['auth', 'insightCatalogue', 'insightStore', 'customReports', 'shared', 'marketExplorers']

const supportedLngs = Object.values(SUPPORTED_LANGUAGES).map((value) => value.isoCode)

const detectionOptions = {
  // order and from where user language should be detected
  order: ['path'],
  // keys or params to lookup language from
  lookupFromPathIndex: 0
}

const initI18 = (): void => {
  void i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      detection: detectionOptions,
      fallbackLng: supportedLngs[0],
      supportedLngs,
      debug: false,
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false
      },
      ns: i18nNamespaces,
      defaultNS: i18nNamespaces[0],
      fallbackNS: i18nNamespaces[0],
      // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
      returnNull: false
    })
}

export default i18n
export {initI18}
