export const emailDomainBlacklist = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'msn.com',
  'live.com',
  'me.com',
  'yahoo.co.uk',
  'googlemail.com',
  'mail.com',
  'inbox.com',
  'rocketmail.com',
  'ymail.com'
]

export const validEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/
export const validDomainEmailRegex = new RegExp(
  `^[\\w-.]+(?!.*@(?:${emailDomainBlacklist.join('|')})$)@([\\w-]+.)+[\\w-]{2,15}$`
)
