/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomReportCatalogueItemDto,
} from '../models';
import {
    CustomReportCatalogueItemDtoFromJSON,
    CustomReportCatalogueItemDtoToJSON,
} from '../models';

export interface CustomReportsFindAllRequest {
    ihqLanguage?: string;
}

/**
 * 
 */
export class CustomReportsApi extends runtime.BaseAPI {

    /**
     * Returns all the custom reports.
     * Get all the custom reports.
     */
    async customReportsFindAllRaw(requestParameters: CustomReportsFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomReportCatalogueItemDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.ihqLanguage !== undefined && requestParameters.ihqLanguage !== null) {
            headerParameters['ihq-language'] = String(requestParameters.ihqLanguage);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/custom-reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomReportCatalogueItemDtoFromJSON));
    }

    /**
     * Returns all the custom reports.
     * Get all the custom reports.
     */
    async customReportsFindAll(requestParameters: CustomReportsFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomReportCatalogueItemDto>> {
        const response = await this.customReportsFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
