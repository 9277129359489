export default {
  tabs: {
    margin: '24px',
    marginTop: '10px'
  },
  tabsTitle: {
    fontFamily: 'BlenderPro_bold',
    fontSize: '1.8rem'
  }
}
