/** @jsxImportSource @emotion/react */
import Stack from '@mui/material/Stack'
import React, {PropsWithChildren} from 'react'

import CompanyFooter from '../../components/companyFooter/companyFooter'
import Header from '../../components/header/header'
import Navbar from '../../components/navbar/navbar'

import styles from './styles'

interface IProps {
  showAuth?: boolean
  showBackButton?: boolean
  showFooter?: boolean
  title?: string
}

const Main = ({
  children,
  showAuth = false,
  showBackButton = false,
  showFooter = true,
  title = 'Insights Store'
}: PropsWithChildren<IProps>): JSX.Element => (
  <main css={styles.root}>
    <Navbar showBackButton={showBackButton} />
    <Stack direction="column" sx={styles.body}>
      <Header showAuth={showAuth} title={title} />
      <div css={styles.childrenContainer}>{children}</div>
      {showFooter && <CompanyFooter />}
    </Stack>
  </main>
)

export default Main
