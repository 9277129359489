import {Auth} from 'aws-amplify'

import {SUPPORTED_LANGUAGES} from '../../../config/constVariables'
import i18n from '../../../config/i18n'
import {ETrackedEvents} from '../../../types'
import {validEmailRegex, validDomainEmailRegex} from '../../../utils/createEmailRegex'
import {trackEvent} from '../../../utils/mixpanel'

interface ISignUpFormData {
  username: string
  password: string
  attributes?: object
}

interface IConfirmSignUpFormData {
  username: string
  code: string
}

const retrieveLocale = (): string => {
  return i18n.language === undefined ? SUPPORTED_LANGUAGES.EN.isoCode : i18n.language
}

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#override-function-calls
export default {
  async handleSignUp(formData: ISignUpFormData) {
    trackEvent(ETrackedEvents.UserSignedUp, {
      Lang: i18n.language,
      'Email confirmed': false
    })

    return await Auth.signUp({
      ...formData,
      attributes: {
        ...formData.attributes,
        locale: retrieveLocale()
      },
      autoSignIn: {
        enabled: true
      }
    })
  },

  async handleConfirmSignUp(formData: IConfirmSignUpFormData) {
    trackEvent(ETrackedEvents.UserSignedUp, {
      Lang: i18n.language,
      'Email confirmed': true
    })

    return await Auth.confirmSignUp(formData.username, formData.code)
  },

  async handleForgotPassword(username: string) {
    const clientMetaData = {locale: retrieveLocale()}

    return await Auth.forgotPassword(username, clientMetaData)
  },

  async validateConfirmPassword(formData: any) {
    if (formData.password !== undefined && formData.password !== formData.confirm_password) {
      return {
        confirm_password: 'Your passwords must match'
      }
    }
    return undefined
  },

  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#sign-up-fields
  async validateCustomSignUp(formData: any) {
    if (formData.given_name?.length === 0) {
      return {
        username: '',
        family_name: '',
        given_name: i18n.t('auth:authScreen.emptyGivenName')
      }
    }

    if (formData.family_name?.length === 0) {
      return {
        username: '',
        family_name: i18n.t('auth:authScreen.emptyFamilyName'),
        given_name: ''
      }
    }

    if (!validEmailRegex.test(formData.username)) {
      return {
        username: i18n.t('auth:authScreen.invalidEmail'),
        family_name: '',
        given_name: ''
      }
    }

    if (!validDomainEmailRegex.test(formData.username)) {
      return {
        username: i18n.t('auth:authScreen.invalidCompanyEmail'),
        family_name: '',
        given_name: ''
      }
    }
  }
}
