/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InsightCatalogueItemDto,
} from '../models';
import {
    InsightCatalogueItemDtoFromJSON,
    InsightCatalogueItemDtoToJSON,
} from '../models';

export interface InsightsFindOneRequest {
    id: string;
}

/**
 * 
 */
export class InsightsApi extends runtime.BaseAPI {

    /**
     * Returns all the Market Explorers.
     * Get all the Market Explorers.
     */
    async insightsFindAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InsightCatalogueItemDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/insights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightCatalogueItemDtoFromJSON));
    }

    /**
     * Returns all the Market Explorers.
     * Get all the Market Explorers.
     */
    async insightsFindAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InsightCatalogueItemDto>> {
        const response = await this.insightsFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the details of a specific market explorer.
     * Get specific market explorer details by id
     */
    async insightsFindOneRaw(requestParameters: InsightsFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsightCatalogueItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsFindOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/insights/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightCatalogueItemDtoFromJSON(jsonValue));
    }

    /**
     * Returns the details of a specific market explorer.
     * Get specific market explorer details by id
     */
    async insightsFindOne(requestParameters: InsightsFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsightCatalogueItemDto> {
        const response = await this.insightsFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
