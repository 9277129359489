import {env} from '../env'

import {CustomReportsApi, DefaultApi, InsightsApi} from './apis'
import {ApiMiddleware} from './middleware'
import {Configuration} from './runtime'

const apiConfig: Configuration = new Configuration({
  basePath: env.REACT_APP_INSIGHT_STORE_BACKEND_URL ?? '',
  middleware: [new ApiMiddleware()]
})

const apiClient = {
  defaultApi: new DefaultApi(apiConfig),
  customReportsApi: new CustomReportsApi(apiConfig),
  insightsApi: new InsightsApi(apiConfig)
}

export default apiClient

export type TApiClient = typeof apiClient
