export default {
  description: {
    textAlign: 'center'
  },
  text: {
    fontFamily: 'noto_sansbold'
  },
  linkContainer: {
    display: 'flex'
  },
  warningIcon: {
    marginRight: '10px'
  },
  link: (color: string) => ({
    color,
    fontSize: '13px',
    marginRight: '50px'
  })
}
