/** @jsxImportSource @emotion/react */
import {useAuthenticator} from '@aws-amplify/ui-react'
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  useMediaQuery,
  Stack,
  Typography
} from '@mui/material'
import {Auth} from 'aws-amplify'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {getSignedIn, setSignedIn} from '../../app/slices/authSlice'
import theme from '../../theme'
import {ETrackedEvents} from '../../types'
import {clearCognitoStorage} from '../../utils/localStorage'
import {trackEvent, reset as mixpanelReset, identify as mixpanelIdentify} from '../../utils/mixpanel'
import AuthScreen from '../authScreen/authScreen'
import {CustomDrawer} from '../customMUI/customDrawer/customDrawer'

import styles from './styles'

const AuthSection = (): JSX.Element => {
  const {t, i18n} = useTranslation()
  const [openLogin, setOpenLogin] = useState<boolean>(false)
  const {signOut, user} = useAuthenticator()
  const [email, setEmail] = useState<string | undefined>(undefined)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [isMobileSignoutOpen, setIsMobileSignoutOpen] = useState<boolean>(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isSignedIn = useAppSelector(getSignedIn)
  const dispatch = useAppDispatch()

  const logOut = useCallback((): void => {
    dispatch(setSignedIn({signedIn: false}))
    clearCognitoStorage()
    setEmail(undefined)
    signOut()
  }, [dispatch, signOut])

  useEffect(() => {
    const getSession = async (): Promise<void> => {
      try {
        await Auth.currentSession()
        const {attributes} = await Auth.currentUserInfo()
        mixpanelIdentify(attributes)
        dispatch(setSignedIn({signedIn: true}))
      } catch (e) {
        dispatch(setSignedIn({signedIn: false}))
      }
    }
    void getSession()
  }, [dispatch])

  const stringToColor = (name: string): string => {
    let hash = 0
    let i

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  const formatString = (name: string): object => {
    const spaceName = name.split(' ')
    return {
      css: {
        backgroundColor: stringToColor(name)
      },
      children: spaceName.length > 1 ? `${spaceName[0][0]}${spaceName[1][0]}` : `${spaceName[0].substring(0, 2)}`
    }
  }

  const stringAvatar = (name: string | undefined): object => {
    return name !== undefined ? formatString(name) : formatString('__')
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    isMobile ? setIsMobileSignoutOpen(!isMobileSignoutOpen) : setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const getEmail = async (): Promise<string> => {
      const session = await Auth.currentAuthenticatedUser()
      return session.attributes.email
    }
    if (isSignedIn) {
      if (user?.attributes?.email === undefined) {
        getEmail()
          .then((userEmail: string) => {
            setEmail(userEmail)
          })
          .catch(() => setEmail('__'))
      } else {
        setEmail(user?.attributes?.email)
      }
    }
  }, [isSignedIn, user?.attributes?.email])
  const {toSignIn} = useAuthenticator()

  const closeAuthScreen = (): void => {
    setOpenLogin(false)
    toSignIn()
  }

  const handleLogout = (): void => {
    logOut()
    trackEvent(ETrackedEvents.UserSignedOut, {
      Lang: i18n.language
    })
    // clear user data and reset $device_id
    mixpanelReset()
    setIsMobileSignoutOpen(!isMobileSignoutOpen)
  }

  return (
    <>
      <div css={styles.loginContainer}>
        {!isSignedIn ? (
          <Button color="secondary" sx={styles.button} onClick={() => setOpenLogin(true)}>
            {t('auth:authSection.button.login')}
          </Button>
        ) : (
          <div>
            <IconButton
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              size="small"
              onClick={handleClick}>
              <Avatar {...stringAvatar(email)} sx={styles.avatar} />
            </IconButton>
            {!isMobile ? (
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                BackdropProps={{
                  style: styles.backdrop
                }}
                css={styles.menu}
                id="account-menu"
                open={open}
                PaperProps={{
                  style: styles.paper
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                onClick={handleClose}
                onClose={handleClose}>
                <MenuItem>
                  <ListItemAvatar css={styles.listItemAvatar}>
                    <Avatar {...stringAvatar(email)} sx={styles.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={email}
                    primaryTypographyProps={styles.menuListPrimary}
                    secondary={email}
                    secondaryTypographyProps={styles.menuListSecondary}
                  />
                </MenuItem>
                <Divider css={styles.bottomDivider} variant="middle" />
                <MenuItem onClick={() => handleLogout()}>{t('auth:authSection.menu.logout')}</MenuItem>
              </Menu>
            ) : (
              <CustomDrawer
                isMobileForm
                drawerAnchor="top"
                open={isMobileSignoutOpen}
                onClose={() => setIsMobileSignoutOpen(false)}>
                <Stack alignItems={'center'} flexWrap={'nowrap'}>
                  <Avatar {...stringAvatar(email)} sx={styles.avatar} />
                  <Typography sx={styles.emailHeader} variant="h6">
                    {email}
                  </Typography>
                  <Button sx={styles.logoutButton} variant="outlined" onClick={() => handleLogout()}>
                    {t('auth:authSection.menu.logout')}
                  </Button>
                </Stack>
              </CustomDrawer>
            )}
          </div>
        )}
        <Modal
          open={openLogin}
          slotProps={{backdrop: {sx: styles.modalBackdrop}}}
          sx={styles.modalContainer}
          onClose={closeAuthScreen}>
          <AuthScreen logOut={logOut} onClose={closeAuthScreen} />
        </Modal>
      </div>
    </>
  )
}

export default AuthSection
